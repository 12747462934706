<template>
  <div class="tenants-banner">
    <div class="container">
      <div class="tenants-banner__icon">
        <svg
          width="85"
          height="91"
          viewBox="0 0 85 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_3066_677)">
            <path
              d="M64.6264 25.2583L13.4325 8C8.47664 16.9333 17.5623 25.784 22.7247 29.0926C18.2644 38.5222 27.474 45.8426 32.6364 48.3241C27.1849 64.7019 41.9286 68.1759 60.513 75L72.1633 40.4654C74.2821 34.1848 70.9075 27.3758 64.6264 25.2583Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3066_677"
              x="0"
              y="0"
              width="84.7959"
              height="91"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3066_677"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3066_677"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <h3 class="tenants-banner__title">{{ $t("rentor") }}</h3>
      <a href="/tenant-questionnaire" class="btn-banner">{{
        $t("fillForm")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TenantsBanner",
};
</script>
